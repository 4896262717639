import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import router from "./router/index.js";
import "element-ui/lib/theme-chalk/index.css";
Vue.config.productionTip = false;
Vue.use(ElementUI);
var store = {
  token: window.localStorage.getItem("token") || null,
  username: window.localStorage.getItem("username") || null,
};
new Vue({
  data: {
    token: store.token,
    username: store.username,
  },
  router,
  render: (h) => h(App),
}).$mount("#app");
