<template>
  <div id="app">
    <header class="continer">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        router
        @select="handleSelect"
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/driver">所有设备</el-menu-item>
        <!-- <el-submenu index="2">
            <template slot="title">我的工作台</template>
            <el-menu-item index="2-1">选项1</el-menu-item>
            <el-menu-item index="2-2">选项2</el-menu-item>
            <el-menu-item index="2-3">选项3</el-menu-item>
            <el-submenu index="2-4">
              <template slot="title">选项4</template>
              <el-menu-item index="2-4-1">选项1</el-menu-item>
              <el-menu-item index="2-4-2">选项2</el-menu-item>
              <el-menu-item index="2-4-3">选项3</el-menu-item>
            </el-submenu>
          </el-submenu> -->
        <el-menu-item v-if="token === null" style="float: right" index="login">
          <el-button type="primary">登录</el-button>
          <!-- <i class="el-icon-user-solid"></i>登录 -->
        </el-menu-item>
        <el-menu-item v-if="token !== null" style="float: right" index="">
          <el-button type="" @click="loginOut">{{
            username ? username : "登录"
          }}</el-button>
          <!-- <i class="el-icon-user-solid"></i>登录 -->
        </el-menu-item>
      </el-menu>
    </header>

    <div class="line"></div>
    <router-view class="continer" :style="{ minHeight: mainHeight + 'px' }" />

    <el-footer class="footer">
      <div class="container">
        <p>
          Copyright © 2015-2022 威海东和文化传媒有限公司 advertdh.com All Rights
          Reserved. 备案号：鲁ICP备19013677号-1
        </p>
      </div>
    </el-footer>

    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      headerHeight: 60,
      footerHeight: 100,
      mainHeight: 1000,
    };
  },
  computed: {
    activeIndex() {
      return this.$route.path;
    },
    token() {
      return this.$root.$data.token;
    },
    username() {
      return this.$root.$data.username;
    },
  },
  watch: {
    token(val) {
      if (val) {
        window.localStorage.setItem("token", val);
      } else if (val === undefined) {
        window.localStorage.removeItem("token");
      }
    },
    username(val) {
      if (val) {
        window.localStorage.setItem("username", val);
      } else if (val === undefined) {
        window.localStorage.removeItem("username");
      }
    },
  },
  created() {
    this.mainHeight =
      window.innerHeight - this.headerHeight - this.footerHeight;
  },
  mounted() {
    console.log(this.$route.path);
  },
  methods: {
    loginOut() {
      this.$confirm("此操作将登出该账户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$router.push("/login");
      });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  padding: 0;
  margin: 0;
  background-color: #fff;
}
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
</style>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.footer {
  background: #232323;
  color: #7a7a7a;
  text-align: center;
  height: 100px !important;
  line-height: 100px !important;
}
.footer:hover {
  color: #c1c1c1;
}
.continer {
  max-width: 1200px;
  margin: 0 auto;
}
.block {
  margin: 0 auto;
  text-align: center;
}
.line {
  background-color: hsla(0, 0%, 100%, 0.95);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-bottom: 1px solid #eee;
}
</style>
