<style scoped>
.driver_type > i {
  font-size: 45px;
  line-height: 45px;
}
</style>

<template>
  <div>
    <el-main>
      <div>
        <el-input
          v-model="UUIDLoading"
          style="width: 30%; margin-right: 10px"
          placeholder="请输入设备的uuid"
          clearable
        >
        </el-input>
        <el-button type="primary" @click="UUID = UUIDLoading">
          查询设备信息（{{ num }}次）
        </el-button>
      </div>

      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column label="类型" width="100">
          <template slot-scope="{ row }">
            <div class="driver_type">
              <i
                v-if="row.uiMode === 'phone'"
                class="iconfont icon-iPhoneX"
              ></i>
              <i
                v-else-if="row.uiMode === 'pad'"
                class="iconfont icon-iPhoneX"
              ></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="msg" label="消息"> </el-table-column>
      </el-table>

      <el-pagination
        :current-page="page"
        :page-size="20"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-main>
  </div>
</template>

<script>
import {
  getSystem,
  createdTempUUID,
  checkTempUUID,
  driverShow,
  driverUpdata,
  messageList,
} from "@/api.js";
import moment from "moment";
export default {
  components: {},
  filters: {
    formatTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  data() {
    return {
      total: 0,
      input: "",
      page: 1,
      downloadData: {
        url: "扫码访问的链接地址",
        icon: "https://img01.yzcdn.cn/vant/leaf.jpg",
      },
      system: {},
      appLoading: true,
      UUIDLoading: "",
      UUID: "",
      dialogVisible: false,
      active: 0,
      dingshiqi: null,
      driver: {
        deviceName: "iPhone",
      },
      driverLoading: true,
      activeIndex: "1",
      activeIndex2: "1",
      tableData: [],
      timer: null,
      num: 0,
    };
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.active = 0;
      }
    },
    UUID(val) {
      if (val) {
        window.localStorage.setItem("UUID", val);
      }
    },

    async active(val) {
      if (val === 0) {
        this.getSystem();
        clearInterval(this.dingshiqi);
        this.dingshiqi = null;
      } else if (val === 1) {
        this.createdTempUUID();
        clearInterval(this.dingshiqi);
        this.dingshiqi = setInterval(() => {
          this.checkTempUUID();
        }, 1000);
      }
    },
  },

  async mounted() {
    // await this.getSystem();
    // await this.getDriverList();
    this.UUIDLoading = window.localStorage.getItem("UUID") || "";
    console.log("%c Line:123 🍎 this.timer", "color:#42b983", this.timer);
    this.timer = setInterval(() => {
      this.getMessageList();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getMessageList();
    },
    editDriver() {
      this.active = 3;
      this.dialogVisible = true;
    },
    async getMessageList() {
      if (!this.UUID) {
        console.log("没有设备uuid");
        return;
      }
      const TempData = {
        page: this.page - 1,
        pageSize: 20,
        uuid: this.UUID,
      };
      try {
        const res = await messageList(TempData);
        this.num++;
        this.total = res.count;
        this.tableData = res.list;
      } catch (error) {
        console.log(
          "%c 🥘 error: ",
          "font-size:20px;background-color: #42b983;color:#fff;",
          error
        );
      }
    },
    async updateDriver() {
      await driverUpdata(this.driver._id, {
        deviceName: this.driver.deviceName,
      });
      this.dialogVisible = false;
      this.page = 1;
      this.getDriverList();
    },
    async regesterDriver(driverId) {
      this.driverLoading = true;
      const res = await driverShow(driverId);
      this.driver = res;
      this.driverLoading = false;
    },
    async getUUID() {
      this.UUIDLoading = true;
      const res = await createdTempUUID();
      this.UUID = JSON.stringify(res);
      this.UUIDLoading = false;
    },
    async checkTempUUID() {
      try {
        const res = await checkTempUUID(JSON.parse(this.UUID));
        if (res.state) {
          this.active = 2;
          clearInterval(this.dingshiqi);
          this.dingshiqi = null;
          this.regesterDriver(res.driverId);
        }
      } catch (error) {
        console.log(
          "%c 🍣 error: ",
          "font-size:20px;background-color: #4b4b4b;color:#fff;",
          error
        );
        this.getUUID();
      }
    },

    async getSystem() {
      this.appLoading = true;
      try {
        const res = await getSystem();
        this.system = res.list;
      } catch (error) {
        console.log(
          "%c 🍧 error: ",
          "font-size:20px;background-color: #E41A6A;color:#fff;",
          error
        );
      }
      this.appLoading = false;
    },
    async createdTempUUID() {
      this.UUIDLoading = true;
      const res = await createdTempUUID();
      this.UUID = JSON.stringify(res);
      this.UUIDLoading = false;
    },
    handleClose() {
      this.dialogVisible = false;
      this.active = 0;
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    open() {
      this.$alert("这是一段内容", "标题名称", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.$message({
            type: "info",
            message: `action: ${action}`,
          });
        },
      });
    },
  },
};
</script>
