import request from "@/lib/request";

// user begin
export function registerUser(data) {
  return request({
    url: "/api/v1/user",
    method: "post",
    data: data,
  });
}

export function loginUser(data) {
  return request({
    url: "/api/v1/auth",
    method: "post",
    data: data,
  });
}

// system begin

export function getSystem(data) {
  return request({
    url: "/api/v1/system",
    method: "get",
    params: data,
  });
}

// uuid begin

export function createdTempUUID() {
  return request({
    url: "/api/v1/driver/createdTempUUID",
    method: "post",
  });
}

export function checkTempUUID(data) {
  return request({
    url: "/api/v1/driver/checkTempUUID",
    method: "get",
    params: data,
  });
}

// driver begin
export function driverShow(id) {
  return request({
    url: "/api/v1/driver/" + id,
    method: "get",
  });
}
export function driverList(data) {
  return request({
    url: "/api/v1/driver",
    method: "get",
    params: data,
  });
}

export function driverUpdata(id, data) {
  return request({
    url: "/api/v1/driver/" + id,
    method: "put",
    data,
  });
}

export function messageList(data) {
  return request({
    url: "/api/v1/pushMsg",
    method: "get",
    params: data,
  });
}
