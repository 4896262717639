<style scoped>
.driver_type > i {
  font-size: 45px;
  line-height: 45px;
}
</style>

<template>
  <div>
    <el-main>
      <el-button type="primary" @click="dialogVisible = true">
        <i class="el-icon-plus"></i> 添加设备
      </el-button>

      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column label="类型" width="100">
          <template slot-scope="{ row }">
            <div class="driver_type">
              <i
                v-if="row.uiMode === 'phone'"
                class="iconfont icon-iPhoneX"
              ></i>
              <i
                v-else-if="row.uiMode === 'pad'"
                class="iconfont icon-iPhoneX"
              ></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="deviceName" label="名称" width="160">
        </el-table-column>
        <el-table-column prop="uuid" label="秘钥">
          <template slot-scope="{ row }">
            <el-button round plain>{{ row.uuid }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="注册时间" width="160">
          <template slot-scope="{ row }">
            <span>{{ row.updatedAt | formatTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160">
          <el-row>
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editDriver"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-position"
              circle
            ></el-button>
            <el-button type="danger" icon="el-icon-delete" circle></el-button>
          </el-row>
        </el-table-column>
      </el-table>

      <el-pagination
        :current-page="page"
        :page-size="20"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-main>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-steps :active="active" align-center>
        <el-step title="下载App" description=""></el-step>
        <el-step title="扫描二维码" description=""></el-step>
        <el-step title="设置备注" description="设置备注信息"></el-step>
      </el-steps>
      <div class="block">
        <div v-if="active === 0" v-loading="appLoading">
          <vue-qr
            :text="system.appDownload"
            :margin="0"
            colorDark="#409eff"
            colorLight="#fff"
            :size="200"
          >
          </vue-qr>
          <p>扫描下方二维码，下载安装</p>
        </div>

        <div v-else-if="active === 1" v-loading="UUIDLoading">
          <vue-qr
            :text="UUID"
            :margin="0"
            colorDark="#409eff"
            colorLight="#fff"
            :size="200"
          >
          </vue-qr>
          <p>打开App扫描二维码注册设备</p>
        </div>

        <div v-else-if="active === 2" v-loading="driverLoading">
          <el-input
            v-model="driver.deviceName"
            placeholder="请输入内容"
          ></el-input>
          <p>设置备注信息</p>
          <el-button type="primary" @click="updateDriver"> 确 定 </el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="handleClose">取 消</el-button> -->
        <el-button v-if="active === 0" style="margin-top: 12px" @click="next">
          下一步
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSystem,
  createdTempUUID,
  checkTempUUID,
  driverShow,
  driverUpdata,
  driverList,
} from "@/api.js";
import moment from "moment";
import vueQr from "vue-qr";
export default {
  components: { vueQr },
  filters: {
    formatTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  data() {
    return {
      total: 0,
      page: 1,
      downloadData: {
        url: "扫码访问的链接地址",
        icon: "https://img01.yzcdn.cn/vant/leaf.jpg",
      },
      system: {},
      appLoading: true,
      UUIDLoading: true,
      UUID: "",
      dialogVisible: false,
      active: 0,
      dingshiqi: null,
      driver: {
        deviceName: "iPhone",
      },
      driverLoading: true,
      activeIndex: "1",
      activeIndex2: "1",
      tableData: [],
    };
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.active = 0;
      }
    },

    async active(val) {
      if (val === 0) {
        this.getSystem();
        clearInterval(this.dingshiqi);
        this.dingshiqi = null;
      } else if (val === 1) {
        this.createdTempUUID();
        clearInterval(this.dingshiqi);
        this.dingshiqi = setInterval(() => {
          this.checkTempUUID();
        }, 1000);
      }
    },
  },
  async mounted() {
    await this.getSystem();
    await this.getDriverList();
  },

  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getDriverList();
    },
    editDriver() {
      this.active = 3;
      this.dialogVisible = true;
    },
    async getDriverList() {
      const TempData = {
        page: this.page - 1,
        pageSize: 20,
      };
      try {
        const res = await driverList(TempData);
        this.total = res.count;
        this.tableData = res.list;
      } catch (error) {
        console.log(
          "%c 🥘 error: ",
          "font-size:20px;background-color: #42b983;color:#fff;",
          error
        );
      }
    },
    async updateDriver() {
      await driverUpdata(this.driver._id, {
        deviceName: this.driver.deviceName,
      });
      this.dialogVisible = false;
      this.page = 1;
      this.getDriverList();
    },
    async regesterDriver(driverId) {
      this.driverLoading = true;
      const res = await driverShow(driverId);
      this.driver = res;
      this.driverLoading = false;
    },
    async getUUID() {
      this.UUIDLoading = true;
      const res = await createdTempUUID();
      this.UUID = JSON.stringify(res);
      this.UUIDLoading = false;
    },
    async checkTempUUID() {
      try {
        const res = await checkTempUUID(JSON.parse(this.UUID));
        if (res.state) {
          this.active = 2;
          clearInterval(this.dingshiqi);
          this.dingshiqi = null;
          this.regesterDriver(res.driverId);
        }
      } catch (error) {
        console.log(
          "%c 🍣 error: ",
          "font-size:20px;background-color: #4b4b4b;color:#fff;",
          error
        );
        this.getUUID();
      }
    },

    async getSystem() {
      this.appLoading = true;
      try {
        const res = await getSystem();
        this.system = res.list;
      } catch (error) {
        console.log(
          "%c 🍧 error: ",
          "font-size:20px;background-color: #E41A6A;color:#fff;",
          error
        );
      }
      this.appLoading = false;
    },
    async createdTempUUID() {
      this.UUIDLoading = true;
      const res = await createdTempUUID();
      this.UUID = JSON.stringify(res);
      this.UUIDLoading = false;
    },
    handleClose() {
      this.dialogVisible = false;
      this.active = 0;
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    open() {
      this.$alert("这是一段内容", "标题名称", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.$message({
            type: "info",
            message: `action: ${action}`,
          });
        },
      });
    },
  },
};
</script>
