<style scoped>
.container {
  justify-content: center;
}
</style>

<template>
  <div>
    <el-container style="margin: 100px 0">
      <el-aside>
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-menu-item index="login" @click="changeActiveIndex('login')">
            <i class="el-icon-menu"></i>
            <span slot="title">登录</span>
          </el-menu-item>
          <el-menu-item index="rigister" @click="changeActiveIndex('rigister')">
            <i class="el-icon-setting"></i>
            <span slot="title">注册</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main class="container">
        <el-form
          ref="form"
          :rules="rules"
          :model="form"
          label-width="80px"
          style="max-width: 380px"
        >
          <!-- @keyup.enter.native="onSubmit()" -->

          <el-form-item label="邮箱" prop="username">
            <el-autocomplete
              v-model="form.username"
              placeholder="请输入一个你常用的邮箱"
              type="email"
              validate-event
              :fetch-suggestions="querySearch"
              style="width: 300px"
              autofocus
              clearable
            >
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="form.password"
              placeholder="输入密码"
              validate-event
              show-password
              clearable
            >
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" :loading="loading" @click="onSubmit">
              {{ activeIndex === "rigister" ? "创建账号" : "立即登录" }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { registerUser, loginUser } from "@/api";
import { Message } from "element-ui";
export default {
  components: {},
  props: {},
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      loading: false,
      activeIndex: "login",
      rules: {
        username: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            message: "密码长度不低于6位",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    loadAll() {
      return [{ value: "三全鲜食（北新泾店）", address: "长宁区新渔路144号" }];
    },
  },
  watch: {},
  created() {
    this.$root.$data.token = undefined;
    this.$root.$data.username = undefined;
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          this.$message({
            message: "请检查是否有错误内容！",
            type: "warning",
          });
        }
      });
    },
    async submit() {
      this.loading = true;
      if (this.activeIndex === "login") {
        try {
          const res = await loginUser(this.form);

          this.$root.$data.token = res.token;
          this.$root.$data.username = res.username;
          this.$router.push("/driver");
          Message({
            message: "登录成功",
            type: "success",
            duration: 5 * 1000,
          });
        } catch (error) {
          console.log(
            "%c 🍌 error: ",
            "font-size:20px;background-color: #3F7CFF;color:#fff;",
            error
          );
        }
      } else {
        try {
          await registerUser(this.form);
          this.activeIndex = "login";
          Message({
            message: "注册成功",
            type: "success",
            duration: 5 * 1000,
          });
        } catch (error) {
          console.log(
            "%c 🥟 error: ",
            "font-size:20px;background-color: #EA7E5C;color:#fff;",
            error
          );
        }
      }
      this.loading = false;
    },
    querySearch(queryString, cb) {
      if (queryString && queryString.indexOf("@") === -1) {
        const res = [
          { value: queryString + "@qq.com" },
          { value: queryString + "@163.com" },
          { value: queryString + "@126.com" },
          { value: queryString + "@live.com" },
          { value: queryString + "@gmail.com" },
          { value: queryString + "@icloud.com" },
        ];
        cb(res);
      } else {
        cb([]);
      }
    },
    changeActiveIndex(str) {
      this.activeIndex = str;
    },
    handleOpen() {},
    handleClose() {},
  },
};
</script>
