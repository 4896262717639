import Vue from "vue";
import Router from "vue-router";
import home from "@/pages/home";
import driver from "@/pages/driver";
import login from "@/pages/login";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: home,
      meta: {
        title: "首页",
      },
      // children: [
      //   {
      //     path: "",
      //     name: "studentIndex",
      //     component: studentIndex,
      //   },
      // ],
    },
    {
      path: "/driver",
      name: "driver",
      component: driver,
      meta: {
        title: "所有设备",
      },
      // children: [
      //   {
      //     path: "",
      //     name: "studentIndex",
      //     component: studentIndex,
      //   },
      // ],
    },
    {
      path: "/login",
      name: "login",
      component: login,
      meta: {
        title: "登录",
      },
      // children: [
      //   {
      //     path: "",
      //     name: "studentIndex",
      //     component: studentIndex,
      //   },
      // ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    // 如果跳转的页面不存在，跳转到404页面
    next("/404");
    return;
  }
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
