import axios from "axios";
import { Message } from "element-ui";
// create an axios instance
// axios.defaults.withCredentials=true
const service = axios.create({
  // baseURL: "http://127.0.0.1:7009",
  baseURL: "https://push.caihongpi.net",

  timeout: 30000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */
  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    if (res.code !== 0) {
      Message({
        message: res.msg || "Error",
        type: "error",
        duration: 3 * 1000,
      });
      return Promise.reject(new Error(res.msg || "Error"));
    } else {
      return res.data;
    }
  },
  (error) => {
    const code = error.request.status;
    const body = JSON.parse(error.request.response);

    if (code === 401) {
      Message({
        message: "登录信息失效请重新登陆",
        type: "error",
        duration: 3 * 1000,
      });
      window.location.href = "/#/login";
    } else if (code === 422) {
      for (let index = 0; index < body.detail.length; index++) {
        if (body.detail[index].message) {
          Message({
            message: body.detail[index].message,
            type: "error",
            duration: 3 * 1000,
          });
        }
      }
      return Promise.reject(error);
    } else {
      Message({
        message: body.msg || "服务器错误，请稍后再试",
        type: "error",
        duration: 3 * 1000,
      });
      return Promise.reject(error);
    }
  }
);

export default service;
